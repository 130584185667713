import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import HomePageSlider from "../components/HomePageSlider";
import ProductList from "../components/ProductList";
// import Cart from "../components/Cart";

const Home = () => {
  return (
    <Container fluid className="m-0 p-0 pt-0">
      <HomePageSlider />
      <Row className="row-fullscreen mx-0 py-3">
        <Col className="22container-product">
          <ProductList />
        </Col>
        {/* <Col className="container-cart">
          <Cart />
        </Col> */}
      </Row>
    </Container>
  );
};

export default Home;
