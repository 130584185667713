export const MAIN_WEBSITE = "https://gorest.co.in/";
export const PRODUCT_URL = "https://gorest.co.in/public-api/products";
//export const PRODUCT_URL = "https://fakestoreapi.com/products/";
// export const PRODUCT_PICTURE_BASE_URL =
//   "https://www.myfoodfunda.com/react/my-shop/images/product_images/";

//export const PRODUCT_PICTURE_BASE_URL2 = "https://picsum.photos/seed/";
//export const PRODUCT_PICTURE_BASE_URL2 = "https://picsum.photos/id/"
export const PRODUCT_PICTURE_BASE_URL3 = "/images/product_images/";

// export const API_BASE = "http://localhost:5000";
export const API_BASE = "https://moonlightbows-server.herokuapp.com";