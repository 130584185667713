
import React from 'react';
import './App.css';
import Header from "./components/Header";

import Footer from "./components/Footer";
import Home from "./pages/Home";
import ProductDetails from './pages/ProductDetails';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Services from './pages/Services';
import Checkout from './pages/Checkout';
import ModalWindow from './components/reusable/ModalWindow';
import OrderComplete from "./pages/OrderComplete";


const App = () => {

  

  return (
    <>
      <Router>
        <Header />

        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/product/:id" exact>
            <ProductDetails />
          </Route>
          <Route path="/services" exact>
            <Services />
          </Route>
          <Route path="/checkout" exact>
            <Checkout />
          </Route>
          <Route path="/order-complete" exact>
            <OrderComplete />
          </Route>
        </Switch>

        <ModalWindow />

        <Footer />
      </Router>
    </>
  );
};

export default App;
