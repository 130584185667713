import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import { useSelector, useDispatch } from "react-redux";
import {useHistory} from 'react-router-dom';
import axios from "axios";

import { API_BASE } from "../../Config";

import {
  CardElement,
  //   CardNumberElement,
  //   CardExpiryElement,
  //   CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const CheckoutForm = ({ billingDetails }) => {
  
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  //   const [metadata, setMetadata] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const cartItems = useSelector( state => state.Cart.cartItems);
  const loggedUser = useSelector (state => state.Settings.loggedUser);

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    // console.log(
    //   "Goign to Call The Stripe Payment Intent:" +
    //     API_BASE +
    //     "/create-payment-intent"
    // );

    axios
      //.post("http://localhost:5000/create-payment-intent", {
      .post(API_BASE + "/create-payment-intent", {
        items: cartItems,
      })
      .then((data) => {
        // console.log("Payment Intent", data);
        // console.log("Client Secret", data.data.clientSecret);
        setClientSecret(data.data.clientSecret);
      })
      .catch((err) => setError(err.message));
  }, [cartItems]);

  const handleSubmit = (event) => {
    // Block native form submission.
    event.preventDefault();

    setProcessing(true);

    //console.log(billingDetails);

    stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      },
    })
    .then(payload => {
      
      //console.log("Payload", payload);
      
      if (payload.error) {
        setError(`Payment failed: ${payload.error.message}`);
        setProcessing(false);
        console.log("[error]", payload.error);
      } else {
        setError(null);
        setSucceeded(true);
        setProcessing(false);
        //   setMetadata(payload.paymentIntent);
        //console.log("[PaymentIntent]", payload.paymentIntent);

        axios.post(API_BASE + '/orders/new', {
          user: loggedUser._id,
          items: cartItems,
          shippingDetails: billingDetails,
          paymentDetails: payload.paymentIntent,
        })
        .then(res => {
          //console.log(res);
          setTimeout( () => {

            dispatch({
              type: "EMPTY_CART",
            });

            history.push('/order-complete');
            
            dispatch({
              type: 'SHOW_HIDE_MODAL',
              payload: {
                status: false
              }
            });

          }, 3000);
        })
        .catch(err => console.log(err));
        //saveFinalOrder();

      }

    });
    
  };

  const cardElementOptions = {
    style: {},
    hidePostalCode: true,
  };

  return (
    // <Elements stripe={stripePromise}>
    <form onSubmit={handleSubmit}>
      <Container className="mt-2">
        <Row className=" justify-content-center">
          <Col xl={11} lg={11} md={11} sm={11} xs={11}>
            <Row>
              <Col xl={12} md={12} sm={12} xs={12} className="p-4 border">
                <CardElement options={cardElementOptions} />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* <button type="submit" disabled={!stripe}> */}

                <Button
                  variant="dark"
                  className=""
                  disabled={processing || !clientSecret || !stripe}
                  onClick={handleSubmit}
                >
                  {processing ? "Processing…" : "Pay"}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>{succeeded ? "Payment Successs" : null}</Col>
            </Row>
            <Row>
              <Col>{error}</Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </form>
    // </Elements>
  );
};

export default CheckoutForm;
