import React from "react";
import { Card, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { PRODUCT_PICTURE_BASE_URL3 } from "../Config";
import { FiEdit } from "react-icons/fi";

import { useHistory } from 'react-router-dom';

const SingleProduct = ({ data, reload, setReload }) => {
  const { _id, title, desc, price, discount, image } = data;

  //const image = PRODUCT_PICTURE_BASE_URL2 + name + '/250/250';
  const img = PRODUCT_PICTURE_BASE_URL3 + image;

  const dispatch = useDispatch();
  const { isDescHidden, isAdmin } = useSelector((state) => state.Settings);

  const history = useHistory();

  const AddProductToCart = () => {
    dispatch({
      type: "ADD_PRODUCT_TO_CART",
      payload: {
        id: _id,
        title,
        image,
        price,
        discount,
        
      },
    });
  };

  const InitProcessEdit = () => {
    dispatch({
      type: "SET_SELECTED_PRODUCT",
      payload: data,
    });

    dispatch({
      type: "SHOW_HIDE_MODAL",
      payload: {
        status: true,
        body: "editProduct",
        extra: [reload, setReload],
      },
    });
    //setShowModal(true);
  };

  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
      <Card className="product-holder ml-0 mt-4">
        {isAdmin ? (
          <FiEdit
            fontSize={20}
            className="admin-edit"
            onClick={InitProcessEdit}
          />
        ) : null}
        <div className="card-img-holder">
          <Card.Img variant="top" src={img} className="h-100 fit-cover" />
        </div>

        <Card.Body className="p-0">
          <Card.Title className="product-title p-2 m-0">{title}</Card.Title>
          <Card.Text
            className={
              "product-short-text p-2 " + (isDescHidden ? "d-none" : "")
            }
          >
            {desc}
          </Card.Text>
          <div className="product-price p-2">
            <span className="price-title">Price: </span>
            {discount !== 0 ? (
              <span className="price-value mr-2 txt-strike">
                £{price.toFixed(2)}
              </span>
            ) : null}
            <span className="price-value">
              £{(price - discount).toFixed(2)}
            </span>
          </div>
          <div className="p-2">
            <Button
              variant="dark"
              block
              onClick={() => history.push("/product/" + _id)}
            >
              More details
            </Button>
            {
              !isAdmin ? 
              <Button variant="success" block onClick={AddProductToCart}>
                Add To Cart
              </Button>
              :
              null
            }
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SingleProduct;
