import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(
  "pk_test_51IIJEgDMnnsfPkSOxfQmd968trXN1tUZBFqboz4AFhhm0IF86u6LhyGfRlX468ZKnwPu68fQFeTklPqjeiso7UqF00PCrTucYi"
);

const CheckoutFormHolder = ({ billingDetails }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm billingDetails={billingDetails} />
    </Elements>
  );
};

export default CheckoutFormHolder;
