const initialProductState = {
  products: [],
  selectedProduct: {},
};

export const ProductReducer = (state = initialProductState, action) => {
  switch (action.type) {
    case "FILL_PRODUCTS": {
      return {
        ...state,
        products: action.payload,
      };
    }

    case "SET_SELECTED_PRODUCT": {
      // console.log("Item Set:", action.payload);

      return {
        ...state,
        selectedProduct: action.payload,
      };
    }

    default:
      return state;
  }
};
