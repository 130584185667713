import React, { useState, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import axios from "axios";
import { API_BASE } from "../Config";
import {useHistory} from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const refMsg = useRef();

  const history = useHistory();

  //   const { isLogin, isAdmin } = useSelector((state) => state);

  const dispatch = useDispatch();

  const DoLogin = () => {

    refMsg.current.innerHTML =
      "User Authentication in progress, please wait ...";
    
    axios
      .post(API_BASE + "/users/login", {
        username,
        password,
      })
      .then((data) => {
        if (data.data.success) {
          if (data.data.data.role === 0) {

            dispatch({
              type: "LOGIN_ADMIN",
              payload: data.data.data,
            });

            dispatch({
              type: "EMPTY_CART",
            });
            
            dispatch({
              type: "SET_CURRENT_MENU_ITEM",
              payload: 1,
            });
            
            history.push('/');
            
          } else if (data.data.data.role === 1) {
            dispatch({
              type: "LOGIN_USER",
              payload: data.data.data,
            });
          }

          refMsg.current.innerHTML = "";
          setUsername("");
          setPassword("");

          dispatch({
            type: "SHOW_HIDE_MODAL",
            payload: {
              status: false,
            },
          });

          //   setShow(false);
        } else {
          refMsg.current.innerHTML = data.data.msg;
        }
        //console.log(data.data);
      })
      .catch((err) => console.log(err));
  };

  const DoSignup = () => {
    dispatch({
      type: "SHOW_HIDE_MODAL",
      payload: {
        status: true,
        body: "signup",
      },
    });
  };

  return (
    <>
      <Row>
        <Col>
          <div className="txt-red" ref={refMsg}></div>
        </Col>
      </Row>

      <Row>
        <Col>
          <label>Username</label>
          <input
            type="text"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Password</label>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </Col>
      </Row>
      <Row>
        <Col className="my-3">
          <Button onClick={DoLogin} block variant="dark">
            Login
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3 text-center">
          Not Registered Yet?{" "}
          <Button className="btn-small" onClick={DoSignup} variant="dark">
            Signup Here
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Login;
