const getLoginState = localStorage.getItem("login-state")
  ? JSON.parse(localStorage.getItem("login-state"))
  : {};

const SaveToLocalStorage = (menuItem) => {
  localStorage.setItem("currentMenuItem", menuItem);
};

const GetMenuItemFromLocalStorage = () => {
  const data = localStorage.getItem("currentMenuItem");

  console.log("currentMenuItem", data);

  if (data) {
    return parseInt(data);
  } else {
    return 1;
  }
};

const initialSettingsState = {
  currentPage: 1,
  selectedProduct: 0,
  isDescHidden: false,
  isModal: false,
  modalBody: "",
  modalExtra: [],
  currentMenuItem: GetMenuItemFromLocalStorage(),
  isLogin: getLoginState.isLogin || false,
  isAdmin: getLoginState.isAdmin || false,
  loggedUser: getLoginState.loggedUser || {},
};

export const SettingsReducer = (state = initialSettingsState, action) => {
  switch (action.type) {
    case "SHOW_HIDE_DESCRIPTION": {
      return {
        ...state,
        isDescHidden: action.payload,
      };
    }

    case "SHOW_HIDE_MODAL": {
      return {
        ...state,
        isModal: action.payload.status,
        modalBody: action.payload.body,
        modalExtra: action.payload.extra,
      };
    }

    case "SET_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }

    case "SET_CURRENT_MENU_ITEM": {
      SaveToLocalStorage(action.payload);

      return {
        ...state,
        currentMenuItem: action.payload,
      };
    }

    case "LOGIN_USER": {
      const obj = {
        ...state,
        isLogin: true,
        isAdmin: false,
        loggedUser: {
          _id: action.payload._id,
          fullname: action.payload.fullname,
          username: action.payload.username,
          email: action.payload.email ? action.payload.email : "",
        },
      };
      localStorage.setItem("login-state", JSON.stringify(obj));
      return obj;
    }
    case "LOGIN_ADMIN": {
      const obj = {
        ...state,
        isLogin: true,
        isAdmin: true,
        loggedUser: {
          _id: action.payload._id,
          fullname: action.payload.fullname,
          username: action.payload.username,
          email: action.payload.email,
        },
      };

      localStorage.setItem("login-state", JSON.stringify(obj));

      return obj;
    }

    case "UPDATE_PROFILE": {

      //console.log("update profile called", action.payload);
      const obj = {
        ...state,
        loggedUser: {
          fullname: action.payload.fullname,
          email: action.payload.email,
        },
      };

      localStorage.setItem("login-state", JSON.stringify(obj));

      return obj;
    }

    case "LOGOUT": {
      const obj = {
        ...state,
        isLogin: false,
        isAdmin: false,
        loggedUser: {},
      };

      localStorage.setItem("login-state", JSON.stringify(obj));
      return obj;
    }

    default:
      return state;
  }
};
