
import React, { useState, useEffect, useRef } from "react";
import SingleProduct from "./SingleProduct";
import axios from "axios";
import {Button} from 'react-bootstrap';
import { API_BASE } from "../Config";
import { useSelector, useDispatch } from "react-redux";
import LoadingMessage from '../components/reusable/LoadingMessage';

const ProductList = () => {
  //const [products, setProducts] = useState([]);

  const [reload, setReload] = useState(false);
  const [productStatus, setProductStatus] = useState(true);

  const products = useSelector((state) => state.Products.products);

  const { isAdmin } = useSelector((state) => state.Settings);

  //const [totalPages, setTotalPages] = useState(0);

  const dispatch = useDispatch();

  const refLoading = useRef();
  const refProductList = useRef();

  useEffect(() => {
    refProductList.current.classList.add("d-none");
    refLoading.current.classList.remove("d-none");

    axios
      .get(API_BASE + "/products" + (productStatus ? '':'/inactive') )
      //.then((res) => setProducts(res.data.data))
      .then((res) => {
        console.log(res.data);
        //setTotalPages(res.data.meta.pagination.pages);
        //setTotalPages(1);
        dispatch({
          type: "FILL_PRODUCTS",
          payload: res.data,
          //payload: res.data,
        });

        refProductList.current.classList.remove("d-none");
        refLoading.current.classList.add("d-none");
      })
      .catch((err) => console.log(err));
  }, [dispatch, reload, productStatus]);

  const AddNewProductModal = () => {
    dispatch({
      type: "SHOW_HIDE_MODAL",
      payload: {
        status: true,
        body: "addProduct",
        extra: [reload, setReload],
      },
    });
  }

  const LoadActiveProducts = () => {
    setProductStatus(true);
    setReload(!reload);
  }
  
  const LoadInactiveProducts = () => {
    setProductStatus(false);
    setReload(!reload);
  }

  return (
    <>
      {/* <div className="container-main-header bg-yellow">Products</div> */}
      <div className="row m-2">
        {/* <ProductPaginationBar totalPages={totalPages} /> */}

        {isAdmin ? (
          <div className="w-100 text-center">
            <Button
              variant="dark"
              className="btn-small mx-1"
              onClick={AddNewProductModal}
            >
              Add New Product
            </Button>
            <Button
              variant="dark"
              className="btn-small mx-1"
              onClick={LoadActiveProducts}
            >
              Load Active Products
            </Button>
            <Button
              variant="dark"
              className="btn-small mx-1"
              onClick={LoadInactiveProducts}
            >
              Load Inactive Products
            </Button>
            {/* <Button variant="dark" className="btn-small" onClick={()=>{}}>Show Active Prodcts</Button>
          <Button variant="dark" className="btn-small" onClick={()=>{}}>Show Inactive Prodcts</Button> */}
          </div>
        ) : null}

        <LoadingMessage
          forwardRef={refLoading}
          msg="Loading data, please wait ..."
        />

        <div ref={refProductList} className="row mb-4 w-100 mx-1">
          {products.map((product, ind) => (
            <SingleProduct
              key={ind}
              data={product}
              reload={reload}
              setReload={setReload}
            />
          ))}
        </div>
        {/* <ProductPaginationBar totalPages={totalPages} /> */}
      </div>
    </>
  );
};

export default ProductList;
