import React, { useState, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API_BASE } from "../Config";

const Profile = () => {
  const loggedUser = useSelector((state) => state.Settings.loggedUser);

  const [userData, setUserData] = useState(loggedUser);

  const refMsg = useRef();

  //   const { isLogin, isAdmin } = useSelector((state) => state);

  const dispatch = useDispatch();

  const UpdateUserData = () => {
    refMsg.current.innerHTML = "Saving profile, please wait ...";

    axios
      .post(API_BASE + "/users/edit", {
        _id: loggedUser._id,
        ...userData,
      })
      .then((data) => {
        if (data.data.status) {
          refMsg.current.innerHTML = data.data.msg;

          dispatch({
            type: "UPDATE_PROFILE",
            payload: userData,
          });
        } else {
          refMsg.current.innerHTML = data.data.msg;
        }
        //console.log(data.data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Row>
        <Col>
          <div className="txt-red" ref={refMsg}></div>
        </Col>
      </Row>

      <Row>
        <Col>
          <label>Full Name</label>
          <input
            type="text"
            onChange={(e) =>
              setUserData({ ...userData, fullname: e.target.value })
            }
            value={userData.fullname}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <label>Email</label>
          <input
            type="text"
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
            value={userData.email}
          />
        </Col>
      </Row>
      <Row>
        <Col className="my-3">
          <Button onClick={UpdateUserData} block variant="dark">
            Update Profile
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
