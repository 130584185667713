import React, { useState, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import axios from "axios";
import { API_BASE } from "../Config";

const AddNewProduct = ({ extra }) => {
  const refMsg = useRef();
  const reload = extra[0];
  const setReload = extra[1];

  const [data, setData] = useState({
    title: "",
    desc: "",
    image: " ",
    price: 5,
    discount: 0,
    isActive: true,
  });

  const dispatch = useDispatch();

  const AddProduct = () => {

    refMsg.current.innerHTML = "Saving new product data, please wait ...";    

    let errors = [];

    if (data.title === "") {
      errors.push("Title can not be empty");
    }

    if (data.desc === "") {
      errors.push("Description can not be empty");
    }

    if (data.image === "") {
      errors.push("Product Image can not be empty");
    }

    if (data.price === "" || isNaN(data.price)) {
      errors.push("Price must be a number");
    }

    if (data.discount === "" || isNaN(data.discount)) {
      errors.push("Discount must be a number");
    }

    if (errors.length > 0) {
      refMsg.current.innerHTML = errors.join("<br />");
      return false;
    }

    axios.post(API_BASE + "/products/add", data).then((res) => {
      if (res.status) {
        dispatch({
          type: "SHOW_HIDE_MODAL",
          payload: {
            status: false,
          },
        });
        setReload(!reload);
      } else {
        refMsg.current.innerHTML = res.msg;
      }
    });
  };

  return (
    <>
      <Row>
        <Col>
          <div className="txt-red" ref={refMsg}></div>
        </Col>
      </Row>

      <Row>
        <Col>
          <label>Title</label>
          <input
            type="text"
            onChange={(e) => setData({ ...data, title: e.target.value })}
            value={data.title}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Description</label>
          <textarea
            onChange={(e) => setData({ ...data, desc: e.target.value })}
            value={data.desc}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Price</label>
          <input
            type="text"
            onChange={(e) => setData({ ...data, price: e.target.value })}
            value={data.price}
          />
        </Col>
        <Col>
          <label>Discount</label>
          <input
            type="text"
            onChange={(e) => setData({ ...data, discount: e.target.value })}
            value={data.discount}
          />
        </Col>
        <Col>
          <label className="d-block">Product Status</label>
          <div className="d-flex">
            <input
              type="checkbox"
              onChange={(e) => setData({ ...data, isActive: e.target.checked })}
              checked={data.isActive}
              className="checkbox mt-2 mr-2"
            />{" "}
            <div className="mt-2">Active</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="my-3">
          <Button onClick={AddProduct} block variant="success">
            Save Changes
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AddNewProduct;
