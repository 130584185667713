import React from "react";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import Form from "react-bootstrap/Form";
// import Badge from "react-bootstrap/Badge";

import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  Button,
  NavDropdown,
  Form,
  Badge,
} from "react-bootstrap";
// import { Cart3 } from "react-bootstrap-icons";
import {GrCart} from 'react-icons/gr';
import { FaUser } from "react-icons/fa";
import { RiLogoutCircleRLine } from "react-icons/ri";
import {CgUserList} from 'react-icons/cg';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import { API_BASE } from "../Config";


const Header = ({ modal }) => {
  const currentMenuItem = useSelector(
    (state) => state.Settings.currentMenuItem
  );

  const dispatch = useDispatch();

  const cartItems = useSelector((state) => state.Cart.cartItems);

  const { isLogin, isAdmin, loggedUser } = useSelector(
    (state) => state.Settings
  );

  const history = useHistory();

  let cartItemsLength = 0;

  const menuItems = [
    {
      id: 1,
      title: "Home",
      link: "/",
      allowedFor: [-1, 0, 1],
    },
    // {
      //  id: 2,     
      //   title: "Services",
      //   link: "/services",
      // },
      {
        id: 3,
        title: "Checkout",
        link: "/checkout",
        allowedFor: [-1, 0],
    },
  ];

  cartItems.forEach((item) => {
    cartItemsLength += item.qty;
  });

  const ShowCartModal = () => {
    dispatch({
      type: "SHOW_HIDE_MODAL",
      payload: {
        status: true,
        body: "cart",
      },
    });
  };

  // const ShowSettingsModal = () => {
  //   dispatch({
  //     type: "SHOW_HIDE_MODAL",
  //     payload: {
  //       status: true,
  //       body: "settings",
  //     },
  //   });
  // };

  const ShowLoginModal = () => {
    dispatch({
      type: "SHOW_HIDE_MODAL",
      payload: {
        status: true,
        body: "login",
      },
    });
  };
  
  const ShowProfileModal = () => {
    dispatch({
      type: "SHOW_HIDE_MODAL",
      payload: {
        status: true,
        body: "profile",
      },
    });
  };

  const MenuItemNavigation = (menuID) => {

    dispatch({
      type: "SET_CURRENT_MENU_ITEM",
      payload: menuID,
    });

    history.push(menuItems.filter(menu => menu.id === menuID)[0].link);
    
  };

  const DoLogout = () => {
    dispatch({
      type: "LOGOUT",
    });
  };

  return (
    <>
      <div className="logo-bg">
        <Container fluid="lg">
          <Row>
            <Col
              className="logo text-lg-left text-md-left text-sm-center text-center logo-holder"
              xs="12"
              sm="12"
              md="3"
              lg="4"
            >
              {/* <img className="logo" src="/images/logo2.png" alt="Logo" /> */}
              <img src="/images/logo.jpg" alt="logo" />
            </Col>
            <Col
              className="right-top-icons text-lg-right text-md-right text-sm-center text-center my-sm-3 mb-3"
              xs="12"
              sm="12"
              md="9"
              lg="8"
            ></Col>
          </Row>
        </Container>
      </div>
      <header>
        <Navbar
          bg="dark"
          variant="light"
          // fixed="top"
          expand="md"
          className="menu-bar container-lg"
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="">
            <Nav className="mr-auto">
              {menuItems.map((menu, ind) => {
                let show = false;

                if (!isLogin) {
                  show = menu.allowedFor.includes(-1) ? true : false;
                } else {
                  if (isAdmin) {
                    show = menu.allowedFor.includes(1) ? true : false;
                  } else {
                    show = menu.allowedFor.includes(0) ? true : false;
                  }
                }

                if (show) {
                  return (
                    <Nav.Link
                      // as={Link}
                      onClick={() => MenuItemNavigation(menu.id)}
                      key={ind}
                      className={menu.id === currentMenuItem ? "active" : ""}
                    >
                      {menu.title}
                    </Nav.Link>
                  );
                } else {
                  return null;
                }
              })}

              {/* <Nav.Link href="#" onClick={ShowSettingsModal}>
                Settings
              </Nav.Link> */}
            </Nav>
            <Form inline>
              {isLogin ? (
                <NavDropdown
                  // as={Link}
                  // menuAlign="right"
                  title={"Welcome " + loggedUser.fullname + "!"}
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item
                    as={Button}
                    onClick={ShowProfileModal}
                    variant="light"
                  >
                    <FaUser fontSize={15} className="mr-2" />
                    My Profile
                  </NavDropdown.Item>
                  {/* <NavDropdown.Divider /> */}
                  <NavDropdown.Item
                    as={Button}
                    onClick={() => {}}
                    variant="light"
                  >
                    <CgUserList fontSize={18} className="mr-2" />
                    My Orders
                  </NavDropdown.Item>
                  {/* <NavDropdown.Divider /> */}
                  <NavDropdown.Item
                    as={Button}
                    onClick={DoLogout}
                    variant="light"
                  >
                    <RiLogoutCircleRLine fontSize={18} className="mr-2" /> Log
                    Out
                  </NavDropdown.Item>
                </NavDropdown>
              ) : null}
              {!isLogin ? (
                <FaUser
                  size={30}
                  onClick={ShowLoginModal}
                  role="button"
                  className="mx-2"
                />
              ) : null}

              {!isAdmin ? (
                <>
                  <GrCart
                    size={30}
                    onClick={ShowCartModal}
                    role="button"
                    className="ml-2"
                  />
                  <Badge variant="dark" onClick={ShowCartModal} role="button">
                    {cartItemsLength}
                  </Badge>
                </>
              ) : null}
            </Form>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
