import React from "react";
import { Card, Button, Media } from "react-bootstrap";
// import { FilePlusFill, FileMinusFill } from "react-bootstrap-icons";
import { FaPlusSquare, FaMinusSquare } from "react-icons/fa";
import {  useDispatch } from "react-redux";
import {PRODUCT_PICTURE_BASE_URL3} from '../Config';

const CartProduct = ({cartItem}) => {

  const { id, title, price, discount, image, qty } = cartItem;
  
  //const product = useSelector(state => state.products.filter(item => item.id === id)[0]);
  const dispatch = useDispatch();

  //const {price, name} = product;

  const RemoveProductFromCart = () => {

    dispatch({
      type: 'DELETE_PRODUCT_FROM_CART',
      payload: {
        id: id,
        qty: 0
      }
    })
    
  }
  
  const IncreaseQty = () => {

    dispatch({
      type: "ADD_PRODUCT_TO_CART",
      payload: {
        id: id,
        title,
        price,
        discount,
        image,
        qty: 1,
      },
    });

  }
  
  const DecreaseQty = () => {

    dispatch({
      type: 'DELETE_PRODUCT_FROM_CART',
      payload: {
        id: id,
        qty: 1,
      }
    })

  }
  
  
  return (
    <Card className="cart-product-holder mb-2">
      <Card.Header className="cart-product-title p-1">{title}</Card.Header>
      <Card.Body className="p-1">
        <Media>
          <img
            width={64}
            height={64}
            className="mr-3"
            //src={PRODUCT_PICTURE_BASE_URL + id + '.jpg'}
            // src={PRODUCT_PICTURE_BASE_URL2 + name + "/250/250"}
            src={PRODUCT_PICTURE_BASE_URL3 + image}
            alt="Generic placeholder"
          />
          <Media.Body>
            <div className="cart-product-price">
              <div className="price-div">
                <span className="price-title">Price: </span>
                <span className="price-value">
                  £ {(price * qty - discount * qty).toFixed(2)}
                </span>
              </div>
            </div>
            <div className="btn-actions">
              <FaMinusSquare size={30} onClick={DecreaseQty} role="button" />
              <div className="price-div">
                ---
                <span className="price-title">{qty}</span>
                ---
              </div>
              <FaPlusSquare size={30} onClick={IncreaseQty} role="button" />
              <Button variant="dark" size="sm" onClick={RemoveProductFromCart}>
                Remove
              </Button>
            </div>
          </Media.Body>
        </Media>
      </Card.Body>
    </Card>
  );
};

export default CartProduct;
