import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Cart from "../Cart";
import Settings from "../Settings";
import Login from "../Login";
import Signup from "../Signup";
import Profile from "../Profile";
import EditProduct from "../EditProduct";
import AddNewProduct from "../AddNewProduct";
import CheckoutFormHolder from "../stripe/CheckoutFormHolder";

const ModalWindow = () => {
  const isModal = useSelector((state) => state.Settings.isModal);
  const body = useSelector((state) => state.Settings.modalBody);
  const extra = useSelector((state) => state.Settings.modalExtra);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: "SHOW_HIDE_MODAL",
      payload: {
        status: false,
        body: "",
        extra: [],
      },
    });
  };

  const modalConfig = {
    cart: {
      heading: "Shopping Cart",
      component: <Cart />,
    },
    settings: {
      heading: "Settings",
      component: <Settings />,
    },
    login: {
      heading: "Login",
      component: <Login />,
    },
    signup: {
      heading: "Signup",
      component: <Signup />,
    },
    profile: {
      heading: "Profile",
      component: <Profile />,
    },
    editProduct: {
      heading: "Edit Product Details",
      component: <EditProduct extra={extra} />,
    },
    addProduct: {
      heading: "Add New Product",
      component: <AddNewProduct extra={extra} />,
    },
    payment: {
      heading: "Payment",
      component: <CheckoutFormHolder billingDetails={extra} />,
    },
  };

  return (
    <Modal show={isModal} onHide={handleClose}>
      <Modal.Header closeButton className="bg-dark-khaki">
        <Modal.Title>{isModal ? modalConfig[body].heading : null}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{isModal ? modalConfig[body].component : null}</Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default ModalWindow;
