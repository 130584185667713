import React from "react";
import { useSelector, useDispatch } from "react-redux";

const Settings = () => {
  const isDescHidden = useSelector((state) => state.Settings.isDescHidden);
  const dispatch = useDispatch();

  return (
    <div>
      Product Description: {"     "}
      <input
        type="radio"
        checked={isDescHidden ? "" : "checked"}
        onClick={() =>
          dispatch({ type: "SHOW_HIDE_DESCRIPTION", payload: false })
        }
      />
      {"     "}
      Show {"    "}
      <input
        type="radio"
        checked={isDescHidden ? "checked" : ""}
        onClick={() =>
          dispatch({ type: "SHOW_HIDE_DESCRIPTION", payload: true })
        }
      />{" "}
      Hide <br />
    </div>
  );
};

export default Settings;
