import React from "react";
import { Carousel } from "react-bootstrap";

const HomePageSlider = () => {

  const slides = [
    // {
    //   img: "bow-ties-australia.png",
    //   heading: "First Slide",
    //   description: "First Slide Description",
    // },
    // {
    //   img: "img_gift_page.png",
    //   heading: "Second Slide",
    //   description: "Second Slide Description",
    // },
    {
      img: "pxby_12_16.jpg",
      heading: "Third Slide",
      description: "Third Slide Description",
    },
    // {
    //   img: "A-BROADWAY-CHRISTMAS-slider.jpg",
    //   heading: "Fourth Slide",
    //   description: "Fourth Slide Description",
    // },
    {
      img: "Splash-Event-Management.jpg",
      heading: "Fifth Slide",
      description: "Fifth Slide Description",
    },
    {
      img: "bow.jpg",
      heading: "Sixth Slide",
      description: "Sixth Slide Description",
    },
  ];

  return (
    <Carousel>
      {slides.map((item, ind) => (
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={"/images/slider/" + item.img}
            alt="slide"
          />
          <Carousel.Caption>
            <h3>{item.heading}</h3>
            <p>{item.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default HomePageSlider;
