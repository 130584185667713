import React from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const CartTotal = ({hideCheckout = false}) => {
  // const products = useSelector((state) => state.Products.products);
  const cartItems = useSelector((state) => state.Cart.cartItems);
  //const isModal = useSelector((state) => state.Settings.isModal);

  const dispatch = useDispatch();

  const history = useHistory();

  const GoToCheckoutPage = () => {

    dispatch({
      type: "SHOW_HIDE_MODAL",
      payload: {
        status: false,
        body: "",
      },
    });

    dispatch({
      type: "SET_CURRENT_MENU_ITEM",
      payload: 3,
    });

    history.push("/checkout");

  };

  let total = 0;

  cartItems.forEach((item) => {
    total += (item.qty * item.price) - (item.qty * item.discount);
  });

  return (
    <div className="cart-total-div p-2 mt-3 mb-3">
      <h3>Total: £ {total.toFixed(2)}</h3>

      {
        !hideCheckout ? 
        <Button block variant="dark" onClick={GoToCheckoutPage}>
          Checkout
        </Button>
        :
        null
      }
    </div>
  );
};

export default CartTotal;
