const SaveToLocalStorage = (items) => {
  localStorage.setItem("cartItems", JSON.stringify(items));
};

const GetCartItemsFromLocalStorage = () => {
  const data = localStorage.getItem("cartItems");

  if (data) {
    return JSON.parse(data);
  } else {
    return [];
  }
};

const initialCartState = {
  cartItems: GetCartItemsFromLocalStorage(),
};

export const CartReducer = (state = initialCartState, action) => {
  switch (action.type) {
    case "ADD_PRODUCT_TO_CART": {
      let find = state.cartItems.filter(
        (item, ind) => item.id === action.payload.id
      );

      let newCartItems;

      if (find.length === 0) {
        newCartItems = [
          ...state.cartItems,
          {
            id: action.payload.id,
            title: action.payload.title,
            image: action.payload.image,
            price: action.payload.price,
            discount: action.payload.discount,
            qty: 1,
          },
        ];

        SaveToLocalStorage(newCartItems);

        return {
          ...state,
          cartItems: newCartItems,
        };
      } else {
        newCartItems = state.cartItems.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              qty: item.qty + 1,
            };
          } else {
            return item;
          }
        });

        const returnObj = {
          ...state,
          cartItems: newCartItems,
        };

        //console.log(returnObj);
        SaveToLocalStorage(newCartItems);

        return returnObj;
      }
    }

    case "DELETE_PRODUCT_FROM_CART": {
      let newCartItems;

      if (action.payload.qty === 0) {
        newCartItems = state.cartItems.filter(
          (item) => item.id !== action.payload.id
        );

        SaveToLocalStorage(newCartItems);

        return {
          ...state,
          cartItems: newCartItems,
        };
      } else {
        const findItem = state.cartItems.filter(
          (item) => item.id === action.payload.id
        );

        if (findItem.length > 0) {
          const new_qty = findItem[0].qty - 1;

          if (new_qty === 0) {
            newCartItems = state.cartItems.filter(
              (item) => item.id !== action.payload.id
            );

            SaveToLocalStorage(newCartItems);

            return {
              ...state,
              cartItems: newCartItems,
            };
          } else {
            newCartItems = state.cartItems.map((item) => {
              if (item.id === action.payload.id) {
                return {
                  ...item,
                  qty: new_qty,
                };
              } else {
                return item;
              }
            });

            SaveToLocalStorage(newCartItems);

            return {
              ...state,
              cartItems: newCartItems,
            };
          }
        }

        break;
      }
    }

    case "EMPTY_CART": {
      SaveToLocalStorage([]);

      return {
        ...state,
        cartItems: [],
      };
    }

    default:
      return state;
  }
};
