import { combineReducers } from "redux";
import { ProductReducer } from "./ProductReducer";
import { SettingsReducer } from "./SettingsReducer";
import { CartReducer } from "./CartReducer";

export const rootReducers = combineReducers({
  Products: ProductReducer,
  Cart: CartReducer,
  Settings: SettingsReducer,
});
