import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Cart from "../components/Cart";
import { useSelector, useDispatch } from "react-redux";



const Checkout = () => {
  const { isAdmin, isLogin, loggedUser } = useSelector(
    (state) => state.Settings
  );
  const dispatch = useDispatch();

  // console.log("updated values", loggedUser);

  const [billingDetails, setBillingDetails] = useState({
      name: '',
      email: '',
      address: {
        city: "",
        line1: "",
        state: "",
        postal_code: "",
        country: "",
      }
  });

  useEffect ( () => {
    setBillingDetails({
      name: loggedUser.fullname,
      email: loggedUser.email,
      address: {
        city: "",
        line1: "",
        state: "",
        postal_code: "",
        country: "",
      },
    });
  }, [loggedUser]);

  const ShowLoginModal = () => {
    dispatch({
      type: "SHOW_HIDE_MODAL",
      payload: {
        status: true,
        body: "login",
      },
    });
  };

  const ShowSignupModal = () => {
    dispatch({
      type: "SHOW_HIDE_MODAL",
      payload: {
        status: true,
        body: "signup",
      },
    });
  };

  const LoadPaymentWindow = () => {
    dispatch({
      type: "SHOW_HIDE_MODAL",
      payload: {
        status: true,
        body: "payment",
        extra: billingDetails,
      },
    });
  };

  return (
    <Container fluid className="m-0 p-0 pt-0">
      <Row className="row-fullscreen mx-0">
        <Col className="container-product">
          {/* <Elements stripe={stripePromise}> */}
            {isLogin && !isAdmin ? (
              // <CheckoutForm />
              <Container className="mt-5">
                <Row className=" justify-content-center">
                  <Col xl={8} lg={10} md={11} sm={11} xs={11}>
                    <Row>
                      <Col>
                        <h4 className="text-center">Shipping Details</h4>
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col lg={3}>
                        <label>Name</label>
                      </Col>
                      <Col>
                        <input
                          type="text"
                          value={billingDetails.name}
                          onChange={(e) =>
                            setBillingDetails({
                              ...billingDetails,
                              name: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col lg={3}>
                        <label>Email</label>
                      </Col>
                      <Col>
                        <input
                          type="text"
                          value={billingDetails.email}
                          onChange={(e) =>
                            setBillingDetails({
                              ...billingDetails,
                              email: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col lg={3}>
                        <label>Address</label>
                      </Col>
                      <Col>
                        <input
                          type="text"
                          value={billingDetails.address.line1}
                          onChange={(e) =>
                            setBillingDetails({
                              ...billingDetails,
                              address: {
                                ...billingDetails.address,
                                line1: e.target.value,
                              },
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col lg={3}>
                        <label>City</label>
                      </Col>
                      <Col>
                        <input
                          type="text"
                          value={billingDetails.address.city}
                          onChange={(e) =>
                            setBillingDetails({
                              ...billingDetails,
                              address: {
                                ...billingDetails.address,
                                city: e.target.value,
                              },
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col lg={3}>
                        <label>State</label>
                      </Col>
                      <Col>
                        <input
                          type="text"
                          value={billingDetails.address.state}
                          onChange={(e) =>
                            setBillingDetails({
                              ...billingDetails,
                              address: {
                                ...billingDetails.address,
                                state: e.target.value,
                              },
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col lg={3}>
                        <label>Postal Code</label>
                      </Col>
                      <Col>
                        <input
                          type="text"
                          value={billingDetails.address.postal_code}
                          onChange={(e) =>
                            setBillingDetails({
                              ...billingDetails,
                              address: {
                                ...billingDetails.address,
                                postal_code: e.target.value,
                              },
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col lg={3}>
                        <label>Country</label>
                      </Col>
                      <Col>
                        <input
                          type="text"
                          value={billingDetails.address.country}
                          onChange={(e) =>
                            setBillingDetails({
                              ...billingDetails,
                              address: {
                                ...billingDetails.address,
                                country: e.target.value,
                              },
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col className="text-center">
                        <Button variant="dark" onClick={LoadPaymentWindow}>
                          Proceed to Payment
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            ) : (
              <>
                <h5 className="mt-4 mb-2 text-center mx-auto">
                  If you are already a member then login to proceed with your
                  order
                </h5>
                <div className="py-2 mb-3 text-center mx-auto">
                  <Button variant="dark" className="" onClick={ShowLoginModal}>
                    Click Here To Login
                  </Button>
                </div>
                <h5 className="mt-5 mb-2 text-center mx-auto">
                  If you have not registered yet
                </h5>
                <div className="py-2 text-center mx-auto">
                  <Button variant="dark" className="" onClick={ShowSignupModal}>
                    Click Here To Signup
                  </Button>
                </div>
              </>
            )}
          {/* </Elements> */}
        </Col>
        <Col className="container-cart">
          <Cart hideCheckout={true} />
        </Col>
      </Row>
    </Container>
  );
};

export default Checkout;
