import React, { useState, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import axios from "axios";
import { API_BASE } from "../Config";

const Signup = () => {
  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const refMsg = useRef();

  //   const { isLogin, isAdmin } = useSelector((state) => state);

  const dispatch = useDispatch();

  const DoSignup = () => {

    refMsg.current.innerHTML = "User Registration in progress, please wait ...";
    

    axios
      .post(API_BASE + "/users/register", {
        fullname,
        username,
        password,
        email,
      })
      .then((data) => {
        if (data.data.success) {
          refMsg.current.innerHTML = data.data.msg;
          setFullname("");
          setUsername("");
          setPassword("");
          setEmail("");
          //   setShow(false);
        } else {
          refMsg.current.innerHTML = data.data.msg.join("<br />");
        }
        //console.log(data.data);
      })
      .catch((err) => console.log(err));
  };

  const DoLogin = () => {
    dispatch({
      type: "SHOW_HIDE_MODAL",
      payload: {
        status: true,
        body: "login",
      },
    });
  };

  return (
    <>
      <Row>
        <Col>
          <div className="txt-red" ref={refMsg}></div>
        </Col>
      </Row>

      <Row>
        <Col>
          <label>Full Name</label>
          <input
            type="text"
            onChange={(e) => setFullname(e.target.value)}
            value={fullname}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Username</label>
          <input
            type="text"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Password</label>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Email</label>
          <input
            type="text"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </Col>
      </Row>
      <Row>
        <Col className="my-3">
          <Button onClick={DoSignup} block variant="dark">
            Signup
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3 text-center">
          Already Registered?{" "}
          <Button
            className="btn-small"
            href="#"
            onClick={DoLogin}
            variant="dark"
          >
            Login Here
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Signup;
