import React from "react";
import { useSelector } from "react-redux";
import CartProduct from "./CartProduct";
import CartTotal from "./CartTotal";

const Cart = ({ hideCheckout = false }) => {
  const cartItems = useSelector((state) => state.Cart.cartItems);

  return (
    <>
      <div className="container-main-header bg-green">Cart</div>
      <div className="p-2">
        {cartItems.map((item, ind) => (
          <CartProduct key={ind} cartItem={item} />
        ))}

        {cartItems.length > 0 ? (
          (
          (
          <CartTotal hideCheckout={hideCheckout} />
        )
        )
        ) : null}
      </div>
    </>
  );
};

export default Cart;
