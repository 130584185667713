import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
//import { useSpring, animated } from "react-spring";
//import "../Spring.css";

const Services = () => {
  const products = useSelector((state) => state.Products.products);
  const currentPage = useSelector((state) => state.Settings.currentPage);

  return (
    <Container fluid className="m-0 p-0 pt-0">
      <Row className="row-fullscreen mx-0">
        <Col className="container-product">
          Total Products: {products.length} <br />
          Current Page: {currentPage}
        </Col>
      </Row>
    </Container>
  );
};

export default Services;
