import React, {useState, useEffect} from "react";

import { Col, Container, Row, Media, Button } from "react-bootstrap";
import Cart from "../components/Cart";
import { API_BASE, PRODUCT_PICTURE_BASE_URL3 } from "../Config";

import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';

const ProductDetails = () => {

  const [product, setProduct] = useState({});
  let { id } = useParams();

  const { isAdmin } = useSelector((state) => state.Settings);

  useEffect ( () => {

    axios.get(API_BASE + '/products/' + id)
    .then(res => {
      console.log(res.data);
      setProduct(res.data);
    })
    .catch(err => console.log(err));

  }, [id])


  //const { title, desc, price, image } = product;
  //const image = PRODUCT_PICTURE_BASE_URL + id + ".jpg";
  //const img = PRODUCT_PICTURE_BASE_URL3 + image;

  const dispatch = useDispatch();

  const AddProductToCart = () => {
    dispatch({
      type: "ADD_PRODUCT_TO_CART",
      payload: {
        id: id,
        title: product.title,
        image: product.image,
        price: product.price,
        discount: product.discount,
      },
    });
  };

  return (
    <Container fluid className="m-0 p-0 pt-0">
      <Row className="row-fullscreen mx-0">
        <Col className="container-product">
          <div className="container-main-header bg-yellow">
            {product?.title}
          </div>
          <div className="row m-3">
            <Media>
              <img
                width={350}
                height={350}
                className="align-self-start mr-3 pic-border-shadow fit-cover"
                src={PRODUCT_PICTURE_BASE_URL3 + product?.image}
                alt={product?.title}
              />
              <Media.Body>
                {/* <h5>Media Heading</h5> */}
                <p>{product?.desc}</p>
              </Media.Body>
            </Media>
          </div>
          <div className="row m-3">
            {!isAdmin ? (
              <Button variant="success" block onClick={AddProductToCart}>
                Add To Cart
              </Button>
            ) : null}
          </div>
        </Col>
        <Col className="container-cart">
          <Cart />
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetails;
